import { read, utils, writeFile } from 'xlsx';

export class ISUniverseSearchTermImportWorkbookHelper {
  static parseWorkbookToArray = async (file: File, onlyHeaders = false): Promise<string[][]> => {
    const workbook = read(await file.arrayBuffer(), { ...(onlyHeaders && { sheetRows: 1 }) });
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = utils.sheet_to_json<string[]>(sheet, { header: 1 });

    return data;
  };

  static parseJsonToCsv(data: { [key: string]: string }[]): void {
    const headers = ['Search term', 'Error (remove this column before uploading again)'];
    const worksheet = utils.json_to_sheet(data);
    utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, `Errors`);

    writeFile(workbook, `search_terms_errors.csv`);
  }
}
