export enum ISPredefinedFiltersEnum {
  TIME = 'Time',
  COUNTRY = 'Country',
  COUNTRIES = 'Country ',
  SOURCE = 'Source',
  AD_GROUP = 'Ad Group',
  CAMPAIGN = 'Campaign',
  RECOMMENDATIONS = 'Recommendations',
  URLS = 'Final URLs',
  KEYWORD_STATUS = 'Keyword status',
  MATCH_TYPE = 'Match type',
  CATEGORIES = 'Categories',
  VALUES = 'Values',
  AVG_LP_EXPERIENCE = 'Avg. LP Experience',
  COMPETITION = 'Competition',
  SEARCH_TERM_DIFFICULTY = 'Search term difficulty',
}
