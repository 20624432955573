import { ISTacticKey } from '@ess/integrated-search/shared/utils';
import { IS_TACTICS_KEYS, IS_TACTICS_RESOURCE_PATH, ISTacticsEnum } from '@ess/integrated-search/tactics/shared/utils';
import {
  IS_UNIVERSE_FILTERS_KEY,
  IS_UNIVERSE_RESOURCE_PATH,
  ISUniverseEnum,
  ISUniverseFiltersKey,
} from '@ess/integrated-search/universe/shared/utils';

import { IS_FILTERS_KEY } from './is-filters-token.const';

const IS_TACTIC_FILTERS_PROPERTY_FACTORY = (resource: ISTacticsEnum): ISTacticKey => IS_TACTICS_KEYS[resource];
const IS_UNIVERSE_FILTERS_PROPERTY_FACTORY = (resource: ISUniverseEnum): ISUniverseFiltersKey =>
  IS_UNIVERSE_FILTERS_KEY[resource];

export const IS_UNIVERSE_FILTERS_PROVIDER = {
  provide: IS_FILTERS_KEY,
  useFactory: IS_UNIVERSE_FILTERS_PROPERTY_FACTORY,
  deps: [IS_UNIVERSE_RESOURCE_PATH],
};

export const IS_TACTICS_FILTERS_PROVIDER = {
  provide: IS_FILTERS_KEY,
  useFactory: IS_TACTIC_FILTERS_PROPERTY_FACTORY,
  deps: [IS_TACTICS_RESOURCE_PATH],
};
