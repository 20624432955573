import { ISUniverseEntitySourceEnum } from '../enums';
import { ISSearchTermWithKeywords } from '../models';

export class ISUniverseSearchTermSourcesHelper {
  static getSources(searchTerm: ISSearchTermWithKeywords): string[] {
    return searchTerm.keywords?.map((keyword) => keyword.source);
  }

  static isManuallyAdded(searchTerm: ISSearchTermWithKeywords): boolean {
    return this.getSources(searchTerm)?.includes(ISUniverseEntitySourceEnum.ADDED_MANUALLY);
  }
}
