import { ISCampaignsAdGroupsFilter, ISCampaignsFilterValue, ISUrlsFilterValue } from '../models';

export class ISDynamicFiltersHelper {
  /**
   * Responsible for adjusting current filters state to updated set of Campaigns and Ad Groups filters options.
   *
   * @param filters current state of filters
   * @param campaigns new set of campaigns
   * @returns updated state of Campaigns and Ad Groups filters adjusted to updated campaigns set
   */
  static getUpdatedCampaignsFilters(
    filters: Partial<ISCampaignsFilterValue>,
    campaigns: ISCampaignsAdGroupsFilter[],
  ): ISCampaignsFilterValue {
    const campaign_ids = campaigns.map((campaign) => campaign.campaign_id);
    const ad_group_ids = campaigns.flatMap((campaign) => campaign.ad_groups.map((adGroup) => adGroup.ad_group_id));

    return {
      campaign_ids: filters.campaign_ids?.filter((id) => campaign_ids.includes(id)),
      ad_group_ids: filters.ad_group_ids?.filter((id) => ad_group_ids.includes(id)),
    };
  }

  /**
   * Responsible for adjusting current filters state to updated set of Urls filters options.
   *
   * @param filters current state of filters
   * @param urls new set of urls
   * @returns updated state of Urls filters adjusted to updated urls set
   */
  static getUpdatedUrlsFilters(filters: ISUrlsFilterValue, urls: string[]): ISUrlsFilterValue {
    return {
      urls: filters.urls?.filter((url) => urls.includes(url)),
    };
  }
}
