import { IS_METRICS_LABELS, ISNumericMetricEnum, ISNumericMetricKey } from '@ess/integrated-search/shared/utils';
import { EssFiltersHelper, EssRangeHelper } from '@ess/shared/utils/helpers';
import {
  SharedFilter,
  SharedFilterBaseValue,
  SharedFilterOption,
  SharedFilters,
  SharedRange,
  SharedRangeData,
  SharedSelectedFilterValue,
  SharedSelectedSecondaryValue,
  SharedTimespan,
} from '@ess/shared/utils/models';

import { IsFiltersHelperAbstract } from './is-filters.helper.abstract';

import { IS_PREDEFINED_FILTERS_KEYS } from '../consts';
import { ISPredefinedFiltersEnum } from '../enums';
import { ISPredefinedFilters, ISPredefinedFiltersKeys } from '../models';

export class ISAppliedFiltersHelper extends IsFiltersHelperAbstract {
  static mapAppliedFiltersValues(options: SharedFilters, filters: ISPredefinedFilters): SharedFilters {
    const applied: SharedFilters = [];
    const predefinedKeys: ISPredefinedFiltersKeys[] = [];
    let categoriesFilter = false;
    let campaignsFilter = false;
    let timespanFilter = false;

    const rangeFilters: Map<ISNumericMetricKey, SharedRangeData> = new Map<ISNumericMetricKey, SharedRangeData>();
    const rangeFiltersKeys: string[] = Object.values<string>(ISNumericMetricEnum);

    Object.entries(filters).forEach(([key, filter]) => {
      if (rangeFiltersKeys.includes(key)) {
        rangeFilters.set(key as ISNumericMetricKey, filter as unknown as SharedRangeData);
      } else {
        predefinedKeys.push(key as ISPredefinedFiltersKeys);
      }
    });

    for (const key of predefinedKeys) {
      const property = IS_PREDEFINED_FILTERS_KEYS[key];
      switch (property) {
        case ISPredefinedFiltersEnum.CATEGORIES:
        case ISPredefinedFiltersEnum.VALUES:
          categoriesFilter = true;
          break;

        case ISPredefinedFiltersEnum.AD_GROUP:
        case ISPredefinedFiltersEnum.CAMPAIGN:
          campaignsFilter = true;
          break;

        case ISPredefinedFiltersEnum.TIME:
          timespanFilter = true;
          break;

        case ISPredefinedFiltersEnum.COUNTRY:
          applied.push(
            ...this.__mapPrimaryFilter(ISPredefinedFiltersEnum.COUNTRY, options, [filters[key]] as string[]),
          );
          break;

        default:
          applied.push(...this.__mapPrimaryFilter(property, options, filters[key] as SharedFilterBaseValue[]));
          break;
      }
    }

    campaignsFilter &&
      applied.push(...this.__mapSecondaryFilter(ISPredefinedFiltersEnum.CAMPAIGN, options, filters.ad_group_ids));

    categoriesFilter &&
      applied.push(
        ...this.__mapSecondaryFilter(ISPredefinedFiltersEnum.CATEGORIES, options, [
          ...(filters.assigned_category_value_ids ?? []),
          ...(filters.not_assigned_category_ids?.map(this.getNotAssignedValueName.bind(this)) ?? []),
        ]),
      );
    timespanFilter && applied.push(...this.__mapTimespanFilter(options, filters.timespan));

    applied.push(
      ...Array.from(rangeFilters.entries()).flatMap(([key, range]) => this.__mapRangeFilter(options, key, range)),
    );
    return applied;
  }

  private static __mapPrimaryFilter(
    property: ISPredefinedFiltersEnum,
    options: SharedFilters,
    filterValue: SharedFilterBaseValue[],
  ): SharedFilterOption[] {
    const option: SharedFilter | null = options.find((o) => o.property === property) ?? null;

    const value =
      option && filterValue
        ? {
            ...option,
            values: (option as SharedFilterOption).values.filter(
              (value) => value?.value && filterValue.includes(value?.value),
            ),
          }
        : null;

    return value && value.values.length ? [value] : [];
  }

  private static __mapSecondaryFilter(
    property: ISPredefinedFiltersEnum,
    options: SharedFilters,
    values?: SharedSelectedFilterValue[],
  ): SharedFilters {
    const option: SharedFilter | null = options.find((o) => o.property === property) ?? null;

    if (option && values?.length) {
      const optionValues: SharedSelectedSecondaryValue[] = (option as SharedFilterOption<number>).values
        .map(
          (value): SharedSelectedSecondaryValue => ({
            parent: value?.value,
            value:
              value?.nestedValues?.filter((nested) => values.includes(nested.value)).map((nested) => nested.value) ??
              [],
          }),
        )
        .filter((value) => value && value.value?.length);
      return EssFiltersHelper.applySecondaryFilters([], optionValues, option as SharedFilterOption<number>);
    }

    return [];
  }

  private static __mapTimespanFilter(
    options: SharedFilters,
    timespan?: SharedTimespan,
  ): SharedFilterOption<SharedTimespan>[] {
    const option: SharedFilter | null = options.find((o) => o.property === ISPredefinedFiltersEnum.TIME) ?? null;
    const value =
      option && timespan
        ? {
            ...option,
            values: EssFiltersHelper.getTimespanFilterValue(timespan),
          }
        : null;

    return value && value.values.length ? [value] : [];
  }

  private static __mapRangeFilter(
    options: SharedFilters,
    key?: ISNumericMetricKey,
    range?: SharedRangeData,
  ): SharedFilterOption<SharedRange>[] {
    if (!range || (!EssRangeHelper.isDefined(range.from_) && !EssRangeHelper.isDefined(range.to))) {
      return [];
    }

    const property: string | null =
      Object.entries(IS_METRICS_LABELS)
        .find(([k, _]) => k === key)
        ?.at(1) ?? null;
    const option: SharedFilter | null = options.find((o) => o.property === property) ?? null;

    const value = option
      ? {
          ...option,
          values: EssFiltersHelper.getRangeFilterValue(EssRangeHelper.getRange(range, option.unit), option.unit),
        }
      : null;

    return value && value.values.length ? [value] : [];
  }
}
