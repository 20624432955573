export enum ISUniverseEntitySourceEnum {
  GOOGLE_ADS = 'ga',
  GOOGLE_SEARCH_CONSOLE = 'gsc',
  ADDED_MANUALLY = 'ma',
}

export enum ISUniverseEntitySourceLabelEnum {
  GOOGLE_ADS = 'Google Ads',
  GOOGLE_SEARCH_CONSOLE = 'Google Search Console',
  ADDED_MANUALLY = 'Manually added',
}
