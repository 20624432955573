import { FormControl } from '@angular/forms';

import { ISCategoryDetails, ISCategoryValue } from '@ess/integrated-search/shared/utils';
import { SharedFormOption } from '@ess/shared/utils/models';

import { ISUniverseEntityCategoryOptionEnum } from '../enums';
import {
  ISUniverseEntitySettingsCategories,
  ISUniverseSettingsCategoriesForm,
  ISUniverseSettingsCategoriesFormData,
  ISUniverseSettingsCategoryControl,
  ISUniverseSettingsCategoryValue,
} from '../models';

export class ISUniverseSettingsCategoriesHelper {
  private static readonly __keepCurrentOption: SharedFormOption<ISUniverseSettingsCategoryValue> = {
    name: ISUniverseEntityCategoryOptionEnum.KEEP_CURRENT,
    value: ISUniverseEntityCategoryOptionEnum.KEEP_CURRENT,
  };

  static getCategoryOptions(category: ISCategoryDetails): SharedFormOption<ISUniverseSettingsCategoryValue>[] {
    return category.values.map((value: ISCategoryValue) => ({ name: value.value, value: value.category_value_id! }));
  }

  static updateCategoryOptions(
    controls: ISUniverseSettingsCategoryControl[],
    formData: ISUniverseSettingsCategoriesFormData,
  ): void {
    controls.forEach((control: ISUniverseSettingsCategoryControl) => {
      const hasKeepCurrent = control.options.some((o) => o.value === ISUniverseEntityCategoryOptionEnum.KEEP_CURRENT);
      const isValueMixed = formData[control.category_id] === ISUniverseEntityCategoryOptionEnum.KEEP_CURRENT;
      if (isValueMixed) {
        !hasKeepCurrent && control.options.unshift({ ...this.__keepCurrentOption });
      } else {
        hasKeepCurrent && control.options.shift();
      }
    });
  }

  static getCategoriesControls(categories: ISCategoryDetails[]): ISUniverseSettingsCategoriesForm {
    return categories.reduce(
      (obj, item) => ({ ...obj, [item.category_id]: new FormControl<ISUniverseSettingsCategoryValue>(null) }),
      {},
    );
  }

  static getCategoriesValuesIds(
    formData: ISUniverseSettingsCategoriesFormData,
    entity: ISUniverseEntitySettingsCategories,
  ): number[] {
    return Object.entries(formData).reduce((arr: number[], [key, value]) => {
      return [
        ...arr,
        ...(value === null
          ? []
          : value === ISUniverseEntityCategoryOptionEnum.KEEP_CURRENT
          ? this.__getCurrentCategoryValue(entity, +key)
          : [value]),
      ];
    }, []);
  }

  static getCategoryFormValue(entities: ISUniverseEntitySettingsCategories[]): ISUniverseSettingsCategoriesFormData {
    return entities.length > 1
      ? this.__getMultipleEntitiesCategoriesValue(entities)
      : this.__getSingleEntityCategoriesValue(entities[0]);
  }

  private static __getCurrentCategoryValue(entity: ISUniverseEntitySettingsCategories, category_id: number): number[] {
    const value: number | null =
      entity.categories.find((c) => c.category_id === category_id)?.values[0].category_value_id ?? null;
    return value ? [value] : [];
  }

  private static __getSingleEntityCategoriesValue(
    entity: ISUniverseEntitySettingsCategories,
  ): ISUniverseSettingsCategoriesFormData {
    return entity.categories.reduce(
      (obj, item) => ({ ...obj, [item.category_id]: item.values[0].category_value_id ?? null }),
      {},
    );
  }

  private static __getMultipleEntitiesCategoriesValue(
    entities: ISUniverseEntitySettingsCategories[],
  ): ISUniverseSettingsCategoriesFormData {
    const entitiesToReduce: ISUniverseEntitySettingsCategories[] = [...entities];
    const startEntity: ISUniverseEntitySettingsCategories = entitiesToReduce.shift()!;
    return entitiesToReduce.reduce((obj: ISUniverseSettingsCategoriesFormData, item) => {
      const currentValues: ISUniverseSettingsCategoriesFormData = this.__getSingleEntityCategoriesValue(item);
      const valueKeys: string[] = Array.from(new Set([...Object.keys(obj), ...Object.keys(currentValues)]));

      return {
        ...valueKeys.reduce(
          (out, key) => ({
            ...out,
            [+key]: currentValues[+key] === obj[+key] ? obj[+key] : ISUniverseEntityCategoryOptionEnum.KEEP_CURRENT,
          }),
          {},
        ),
      };
    }, this.__getSingleEntityCategoriesValue(startEntity));
  }
}
