import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ISCategoryDetails, ISCategoryMatch, ISCategorySearchMatchResponse } from '@ess/integrated-search/shared/utils';
import { SharedFormOption } from '@ess/shared/utils/models';

import { ISUniverseAddCategoryActionEnum } from '../enums';
import {
  ISUniverseAddCategoriesArrayItem,
  ISUniverseAddCategoriesForm,
  ISUniverseAddCategory,
  ISUniverseAddCategoryExisting,
  ISUniverseAddCategoryForm,
  ISUniverseAddCategoryFormData,
  ISUniverseAddSearchTerm,
  ISUniverseAddSearchTermError,
} from '../models';

export class ISUniverseSearchTermImportHelper {
  static parseCategoriesToForm(
    categoriesMap: Map<string, string[]>,
    categoriesForm: FormGroup<ISUniverseAddCategoriesForm>,
  ): void {
    const categoriesArray: FormArray<ISUniverseAddCategoriesArrayItem> = categoriesForm.controls.categories;
    categoriesArray.clear({ emitEvent: false });
    const controls: FormGroup<ISUniverseAddCategoryForm>[] = [];
    categoriesMap.forEach((values: string[], title: string) => controls.push(this.__getCategoryForm(title, values)));
    controls.sort((a, b) => (a.value!.title! >= b.value!.title! ? 1 : -1));
    controls.forEach((control) => categoriesArray.push(control, { emitEvent: false }));
  }

  static patchMatchesToForm(
    response: ISCategorySearchMatchResponse,
    categoriesArray: FormArray<ISUniverseAddCategoriesArrayItem>,
    projectId: number,
  ): void {
    response.categories.map((match: ISCategoryMatch) => {
      const control = categoriesArray.controls.find((c) => c.value.title === match.title);
      control && this.__patchCategoryControl(control, match, projectId);
    });
  }

  static getCategoriesForProjectCount(
    categoriesFormData: ISUniverseAddCategoryFormData[],
    categories: ISCategoryDetails[],
  ): [number, ISUniverseAddCategory[]] {
    const toAdd: ISUniverseAddCategory[] = [];
    let limit = categories.length;
    categoriesFormData.forEach((category) => {
      if (category.action && category.action !== ISUniverseAddCategoryActionEnum.DISCARD) {
        if (category.categoryId && category.action !== ISUniverseAddCategoryActionEnum.CREATE) {
          const existingInProject = categories.find((c) => c.category_id === category.categoryId) ?? null;
          const selectedExisting = category.existingCategories.find((c) => c.category_id === category.categoryId)!;
          if (existingInProject === null) {
            limit++;
          }
          const addCategoryPayload: ISUniverseAddCategory = {
            category_id: category.categoryId,
            title: category.title,
            new_values: category.action === ISUniverseAddCategoryActionEnum.MERGE ? selectedExisting.newValues : [],
          };
          toAdd.push(addCategoryPayload);
        } else {
          limit++;
          toAdd.push({
            category_id: null,
            title: category.title,
            new_values: category.values,
          });
        }
      }
    });
    return [limit, toAdd];
  }

  static parseArrayToEntities(data: string[][], categories: ISCategoryDetails[]): ISUniverseAddSearchTerm[] {
    const [headerRow, ...entityData] = data;
    const headers = headerRow.map((header) => header.toLowerCase().trim());

    const entityColumnIndex: number = headers.findIndex((h) =>
      ['search term', 'search terms', 'search term name'].includes(h),
    );
    const categoriesIndexMap: Map<string, number> = headers.reduce<Map<string, number>>((map, header, index) => {
      if (index !== entityColumnIndex) {
        map.set(header, index);
      }
      return map;
    }, new Map<string, number>());

    const categoriesValuesMap: Map<number, Map<string, number>> = categories.reduce((map, category) => {
      const index = categoriesIndexMap.get(category.title) ?? null;
      if (index !== null) {
        const valuesMap: Map<string, number> = category.values.reduce((valueMap, value) => {
          valueMap.set(value.value, value.category_value_id!);
          return valueMap;
        }, new Map<string, number>());
        map.set(index, valuesMap);
      }
      return map;
    }, new Map<number, Map<string, number>>());

    return entityData.reduce<ISUniverseAddSearchTerm[]>(
      (entities, row) => [
        ...entities,
        {
          search_term: row[entityColumnIndex] ?? '',
          category_value_ids: row.reduce<number[]>((ids, value, index) => {
            const id: number | null = categoriesValuesMap.get(index)?.get(value?.toLowerCase().trim()) ?? null;
            id && ids.push(id);
            return ids;
          }, []),
        },
      ],
      [],
    );
  }

  static parseErrors(
    errors: ISUniverseAddSearchTermError[],
    categories: ISCategoryDetails[],
  ): {
    [key: string]: string;
  }[] {
    const categoriesValues = categories.map((category) => ({
      title: category.title,
      values: category.values.reduce((valueMap, value) => {
        valueMap.set(value.category_value_id!, value.value);
        return valueMap;
      }, new Map<number, string>()),
    }));

    const data = errors.map(({ category_value_ids, ...error }) =>
      category_value_ids.reduce(
        (row, id) => {
          const category = categoriesValues.find((c) => c.values.has(id));
          return category
            ? {
                ...row,
                [category.title]: category.values.get(id),
              }
            : row;
        },
        { ...error },
      ),
    );

    return data;
  }

  private static __getCategoryForm(title: string, values: string[]): FormGroup<ISUniverseAddCategoryForm> {
    return new FormGroup<ISUniverseAddCategoryForm>(
      {
        categoryId: new FormControl<number | null>(null),
        title: new FormControl<string>(title, { nonNullable: true }),
        values: new FormControl<string[]>(values, { nonNullable: true }),
        existingCategories: new FormControl<ISUniverseAddCategoryExisting[]>([], { nonNullable: true }),
        options: new FormControl<SharedFormOption<ISUniverseAddCategoryActionEnum>[]>([], { nonNullable: true }),
        action: new FormControl<ISUniverseAddCategoryActionEnum | null>(null),
        info: new FormControl<string | null>(null),
      },
      [this.__categoryFormValidator()],
    );
  }

  private static __categoryFormValidator = (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const category = control.value;
      if (category && category.action === ISUniverseAddCategoryActionEnum.MERGE) {
        const existing = category.existingCategories.find((c: any) => c.category_id === category.categoryId);
        const sum = existing?.values?.concat(existing?.newValues ?? []) ?? [];
        if (sum.length > 50) {
          return {
            valuesLimit:
              'This category cannot be merged with new values – it would exceed values limit for one category. Choose another category:',
          };
        }
      }
      return null;
    };
  };

  private static __patchCategoryControl(
    control: ISUniverseAddCategoriesArrayItem,
    match: ISCategoryMatch,
    projectId: number,
  ): void {
    const newValues: string[] = control.getRawValue().values;
    const existingCategories: ISUniverseAddCategoryExisting[] = match.existing_categories.map(
      ({ category_id, title, last_updated, description, values, assigned_projects, active }) => ({
        category_id,
        title,
        last_updated,
        description,
        active,
        values: values.map((v) => v.value),
        assigned_project_names: assigned_projects.map((p) => p.project_name),
        newValues: newValues.filter((newValue) => !values.some((v) => v.value === newValue)),
      }),
    );

    let actions: ISUniverseAddCategoryActionEnum[] = [ISUniverseAddCategoryActionEnum.DISCARD];
    let info: string | null = null;

    if (existingCategories.length > 0) {
      actions.unshift(ISUniverseAddCategoryActionEnum.USE_EXISTING);
      if (match.existing_categories[0].assigned_projects.some((p) => p.project_id === projectId)) {
        if (existingCategories[0].newValues.length) {
          info = 'Category with this name already exists in this client and this project.';
          if (existingCategories[0].newValues.length + existingCategories[0].values.length <= 50) {
            actions.unshift(ISUniverseAddCategoryActionEnum.MERGE);
          }
        } else {
          actions = [];
          info =
            'This category with these values already exists in this client and this project. It will be updated with uploaded search terms.';
        }
      } else {
        actions.unshift(ISUniverseAddCategoryActionEnum.CREATE);
        info = 'Category with this name already exists in this client.';
        if (existingCategories.some((c) => c.newValues.length && c.newValues.length + c.values.length <= 50)) {
          actions.unshift(ISUniverseAddCategoryActionEnum.MERGE);
        }
      }
    } else {
      actions.unshift(ISUniverseAddCategoryActionEnum.CREATE);
    }

    const action: ISUniverseAddCategoryActionEnum | null = control.value.action ?? actions[0] ?? null;
    const categoryId: number | null = existingCategories[0]?.category_id ?? null;

    control.patchValue({
      categoryId,
      existingCategories,
      options: actions.map((a) => ({ name: a, value: a })),
      action,
      info,
    });
  }
}
