import {
  IS_KEYWORD_MATCH_TYPE_LABELS,
  IS_KEYWORD_STATUS_LABELS,
  IS_LANDING_PAGE_QUALITY_SCORE_LABELS,
  IS_METRICS_LABELS,
  ISCategoryDetails,
  ISCompetitionTypesEnum,
  ISKeywordMatchTypeEnum,
  ISKeywordStatusEnum,
  ISLandingPageQualityScoreEnum,
  ISNumericMetricKey,
  ISSearchTermDifficultyTypesEnum,
} from '@ess/integrated-search/shared/utils';
import {
  IS_COMPETITION_TYPES_LABELS,
  IS_SEARCH_TERM_DIFFICULTY_LABELS,
  IS_TACTICS_RECOMMENDATION_LABELS,
  IS_TACTICS_RECOMMENDATIONS,
  ISTacticsEnum,
} from '@ess/integrated-search/tactics/shared/utils';
import { IS_ENTITY_SOURCE_LABELS, ISUniverseEntitySourceEnum } from '@ess/integrated-search/universe/shared/utils';
import { SharedCalendarConfig } from '@ess/shared/utils/classes';
import { SharedFilterTypeEnum } from '@ess/shared/utils/enums';
import { EssCountryOptionsHelper, SharedTimespanHelper } from '@ess/shared/utils/helpers';
import { SharedFilterOption, SharedFilterValue, SharedTimespan } from '@ess/shared/utils/models';

import { IsFiltersHelperAbstract } from './is-filters.helper.abstract';

import { ISFiltersSectionEnum, ISPredefinedFiltersEnum } from '../enums';
import { ISCampaignsAdGroupsFilter } from '../models';

export class ISFiltersOptionsHelper extends IsFiltersHelperAbstract {
  static getTimeFilterOption(tooltip = '', removable = false): SharedFilterOption<SharedTimespan> {
    const values = SharedTimespanHelper.getPresetOptions();
    const lastAvailable: SharedTimespan = values.at(-1)!.value;

    const config: Partial<SharedCalendarConfig> = {
      minDate: new Date(lastAvailable.start_date),
      maxDate: new Date(lastAvailable.end_date),
    };

    return {
      property: ISPredefinedFiltersEnum.TIME,
      type: SharedFilterTypeEnum.TIMESPAN,
      values,
      removable,
      tooltip,
      config,
    };
  }

  static getCountryFilterOptions(): SharedFilterOption {
    return {
      property: ISPredefinedFiltersEnum.COUNTRY,
      type: SharedFilterTypeEnum.DEFAULT,
      values: EssCountryOptionsHelper.getCountryOptionList().map((country) => ({
        value: country.value!,
        label: country.value!,
        extraLabel: `(${country.name})`,
      })),
      multiple: false,
      removable: false,
    };
  }

  static getCountriesFilterOptions(): SharedFilterOption {
    return {
      property: ISPredefinedFiltersEnum.COUNTRIES,
      type: SharedFilterTypeEnum.DEFAULT,
      values: EssCountryOptionsHelper.getCountryOptionList().map((country) => ({
        value: country.value!,
        label: country.value!,
        extraLabel: `(${country.name})`,
      })),
      multiple: true,
      removable: true,
    };
  }

  static getKeywordStatusFilterOptions(): SharedFilterOption {
    return {
      property: ISPredefinedFiltersEnum.KEYWORD_STATUS,
      type: SharedFilterTypeEnum.DEFAULT,
      values: Object.values(ISKeywordStatusEnum).map((status) => ({
        value: status,
        label: IS_KEYWORD_STATUS_LABELS[status],
      })),
      multiple: true,
      removable: true,
    };
  }

  static getMatchTypeFilterOptions(): SharedFilterOption {
    return {
      property: ISPredefinedFiltersEnum.MATCH_TYPE,
      type: SharedFilterTypeEnum.DEFAULT,
      values: Object.values(ISKeywordMatchTypeEnum).map((matchType) => ({
        value: matchType,
        label: IS_KEYWORD_MATCH_TYPE_LABELS[matchType],
      })),
      multiple: true,
      removable: true,
    };
  }

  static getCategoriesFilterOptions(categories: ISCategoryDetails[]): SharedFilterOption {
    const values = categories.map<SharedFilterValue>((category) => ({
      value: category.category_id,
      label: category.title,
      valueId: category.category_id,
      nestedValues: [
        {
          value: this.getNotAssignedValueName(category.category_id),
          label: 'Not Assigned',
          tooltip: `Category: ${category.title}`,
          parent: category.category_id,
          section: category.title,
        },
        ...category.values.map((value) => ({
          value: value.category_value_id!,
          label: value.value,
          tooltip: `Category: ${category.title}`,
          parent: category.category_id,
          section: category.title,
        })),
      ],
    }));

    return {
      property: ISPredefinedFiltersEnum.CATEGORIES,
      secondaryProperty: ISPredefinedFiltersEnum.VALUES,
      type: SharedFilterTypeEnum.NESTED,
      values,
      multiple: true,
      removable: true,
      label: 'Categories',
      ...(!values.length && {
        disabled: true,
        disabledMessage: 'There are no categories for this project.',
      }),
    };
  }

  static getCampaignFilterOptions(campaigns: ISCampaignsAdGroupsFilter[]): SharedFilterOption {
    const values: SharedFilterValue[] = campaigns.map((campaign) => ({
      value: campaign.campaign_id,
      label: campaign.campaign_name,
      extraLabel: `ID: ${campaign.campaign_id}`,
      valueId: campaign.campaign_id,
      nestedValues: campaign.ad_groups.map((adGroup) => ({
        value: adGroup.ad_group_id,
        label: adGroup.ad_group_name,
        extraLabel: `ID: ${adGroup.ad_group_id}`,
        tooltip: `Campaign: ${campaign.campaign_name}`,
        parent: campaign.campaign_id,
        section: campaign.campaign_name,
      })),
    }));

    return {
      property: ISPredefinedFiltersEnum.CAMPAIGN,
      secondaryProperty: ISPredefinedFiltersEnum.AD_GROUP,
      type: SharedFilterTypeEnum.NESTED,
      values,
      multiple: true,
      removable: true,
      description: 'This list varies depending on selected time frame and country.',
      label: 'Campaign & Ad Group',
      ...(!values.length && {
        disabled: true,
        disabledMessage: 'There are no campaigns for selected time frame and/or country.',
      }),
    };
  }

  static getUrlFilterOptions(urls: string[]): SharedFilterOption {
    const values = urls.map((url) => ({ value: url, label: url }));
    return {
      property: ISPredefinedFiltersEnum.URLS,
      type: SharedFilterTypeEnum.DEFAULT,
      values,
      multiple: true,
      removable: true,
      description: 'This list varies depending on selected time frame and country.',
      valueClass: 'lowercase',
      ...(!values.length && {
        disabled: true,
        disabledMessage: 'There are no URLs for selected time frame and/or country.',
      }),
    };
  }

  static getRecommendationsFilterOptions(tactic: ISTacticsEnum): SharedFilterOption {
    const recommendations: SharedFilterValue[] = IS_TACTICS_RECOMMENDATIONS[tactic].map((recommendation) => ({
      value: recommendation,
      label: IS_TACTICS_RECOMMENDATION_LABELS[recommendation] || 'No recommendation',
    }));

    return {
      property: ISPredefinedFiltersEnum.RECOMMENDATIONS,
      type: SharedFilterTypeEnum.DEFAULT,
      values: recommendations,
      multiple: true,
      removable: true,
    };
  }

  static getSourcesFilterOptions(withManual = false): SharedFilterOption {
    return {
      property: ISPredefinedFiltersEnum.SOURCE,
      type: SharedFilterTypeEnum.DEFAULT,
      values: Object.values(ISUniverseEntitySourceEnum)
        .filter((s) => s !== ISUniverseEntitySourceEnum.ADDED_MANUALLY || withManual)
        .map((source) => ({
          value: source,
          label: IS_ENTITY_SOURCE_LABELS[source],
        })),
      multiple: true,
      removable: true,
    };
  }

  static getAvgLPExperienceFilterOptions(): SharedFilterOption {
    return {
      property: ISPredefinedFiltersEnum.AVG_LP_EXPERIENCE,
      type: SharedFilterTypeEnum.DEFAULT,
      values: Object.values(ISLandingPageQualityScoreEnum).map((score) => ({
        value: score,
        label: IS_LANDING_PAGE_QUALITY_SCORE_LABELS[score],
      })),
      multiple: true,
      removable: true,
      section: ISFiltersSectionEnum.METRICS,
    };
  }

  static getCompetitionFilterOptions(): SharedFilterOption {
    return {
      property: ISPredefinedFiltersEnum.COMPETITION,
      type: SharedFilterTypeEnum.DEFAULT,
      values: Object.values(ISCompetitionTypesEnum).map((competition) => ({
        value: competition,
        label: IS_COMPETITION_TYPES_LABELS[competition],
      })),
      multiple: true,
      removable: true,
      section: ISFiltersSectionEnum.METRICS,
    };
  }

  static getSearchTermDifficultyFilterOptions(): SharedFilterOption {
    return {
      property: ISPredefinedFiltersEnum.SEARCH_TERM_DIFFICULTY,
      type: SharedFilterTypeEnum.DEFAULT,
      values: Object.values(ISSearchTermDifficultyTypesEnum).map((difficulty) => ({
        value: difficulty,
        label: IS_SEARCH_TERM_DIFFICULTY_LABELS[difficulty],
      })),
      multiple: true,
      removable: true,
      section: ISFiltersSectionEnum.METRICS,
    };
  }

  static getNumericMetricFilterOption(
    metric: ISNumericMetricKey,
    unit?: string | null,
    allowAnyRange = false,
  ): SharedFilterOption {
    return {
      property: IS_METRICS_LABELS[metric],
      type: SharedFilterTypeEnum.RANGE,
      values: [],
      removable: true,
      section: ISFiltersSectionEnum.METRICS,
      unit,
      config: { allowAnyRange },
    };
  }
}
