import { ISFlatSearchTerm, ISSearchTermRow, ISSearchTermWithKeywords } from '../models';

export class ISSearchTermsRowsHelper {
  static flattenSearchTerm(searchTerm: ISSearchTermWithKeywords): ISFlatSearchTerm[] {
    return searchTerm.keywords.map<ISFlatSearchTerm>((keyword) => ({
      search_term: searchTerm.search_term,
      keyword: keyword.keyword,
      match_type: keyword.match_type,
      campaign: keyword.campaign,
      ad_group: keyword.ad_group,
      countries: keyword.countries ?? [],
      source: [keyword.source],
      categories: searchTerm.categories,
      is_active: keyword.is_active,
    }));
  }

  static mapSearchTermRows(searchTerms: ISSearchTermWithKeywords[]): ISSearchTermRow[] {
    return searchTerms
      .flatMap<ISSearchTermRow>((searchTerm: ISSearchTermWithKeywords) => {
        const rows: ISFlatSearchTerm[] = this.flattenSearchTerm(searchTerm);
        return [
          {
            ...searchTerm,
            isMaster: true,
          },
          ...rows.filter((row) => row.keyword),
        ];
      })
      .map((row, index) => ({ ...row, rowId: index }));
  }
}
