import { ISUniverseEnum } from '../enums';
import { ISUniverseTooltipDictionary } from '../models';

export const IS_TACTICS_TOOLTIP_DICTIONARY: ISUniverseTooltipDictionary = {
  [ISUniverseEnum.SEARCH_TERMS]: {
    timeFilter:
      'By applying time filter, you’ll see only those search terms that existed in selected time range. This affects results in table.',
  },
  [ISUniverseEnum.URLS]: {
    timeFilter:
      'By applying time filter, you’ll see only those landing pages that existed in selected time range. This affects results in table.',
  },
};
