import { IS_METRICS_LABELS } from '@ess/integrated-search/shared/utils';
import { SharedFilterTypeEnum } from '@ess/shared/utils/enums';
import { EssRangeHelper } from '@ess/shared/utils/helpers';
import {
  SharedFilterOption,
  SharedFilters,
  SharedFilterValue,
  SharedRange,
  SharedTimespan,
} from '@ess/shared/utils/models';

import { ISFiltersOptionsHelper } from './is-filters-options.helper';

import { IS_PREDEFINED_FILTERS_BASE_KEYS } from '../consts';
import { ISPredefinedFiltersEnum } from '../enums';
import {
  ISCampaignsFilterValue,
  ISCategoriesFilterValue,
  ISPredefinedFilters,
  ISPredefinedFiltersKeys,
} from '../models';

export class ISFiltersHelper extends ISFiltersOptionsHelper {
  static mapFilterValues<F extends ISPredefinedFilters>(appliedFilters: SharedFilters): F {
    let value: ISPredefinedFilters = {};
    const categoriesFilters: SharedFilterOption[] = [];
    const campaignsFilters: SharedFilterOption[] = [];

    for (const appliedFilter of appliedFilters) {
      if (appliedFilter.type === SharedFilterTypeEnum.TIMESPAN) {
        const timespan: SharedFilterValue<SharedTimespan> | null =
          (appliedFilter as SharedFilterOption<SharedTimespan>).values.at(0) ?? null;
        value = {
          ...value,
          ...(timespan && { timespan: timespan.value }),
        };
      } else if (appliedFilter.type === SharedFilterTypeEnum.RANGE) {
        const range: SharedFilterValue<SharedRange> | null =
          (appliedFilter as SharedFilterOption<SharedRange>).values.at(0) ?? null;
        const key: string | null =
          Object.entries(IS_METRICS_LABELS)
            .find(([_, value]) => value === appliedFilter.property)
            ?.at(0) ?? null;
        value = {
          ...value,
          ...(range && key && { [key]: EssRangeHelper.getRangeData(range.value, appliedFilter.unit) }),
        };
      } else {
        const filter: SharedFilterOption = appliedFilter as SharedFilterOption;
        switch (filter.property) {
          case ISPredefinedFiltersEnum.VALUES:
            categoriesFilters.push(filter);
            break;

          case ISPredefinedFiltersEnum.AD_GROUP:
            filter.filterId && campaignsFilters.push(filter);
            break;

          case ISPredefinedFiltersEnum.COUNTRY:
            value.country = this.__getFilterValue<string>(filter)[0];
            break;

          case ISPredefinedFiltersEnum.RECOMMENDATIONS:
          case ISPredefinedFiltersEnum.URLS:
          case ISPredefinedFiltersEnum.SOURCE:
          case ISPredefinedFiltersEnum.COUNTRIES:
          case ISPredefinedFiltersEnum.MATCH_TYPE:
          case ISPredefinedFiltersEnum.KEYWORD_STATUS:
          case ISPredefinedFiltersEnum.AVG_LP_EXPERIENCE:
          case ISPredefinedFiltersEnum.COMPETITION:
          case ISPredefinedFiltersEnum.SEARCH_TERM_DIFFICULTY:
            value = this.__getFilterBaseValue(filter, value);
            break;
        }
      }
    }

    const { campaign_ids, ad_group_ids } = ISFiltersHelper.__getCampaignsFilterValue(campaignsFilters);
    const { not_assigned_category_ids, assigned_category_value_ids } =
      ISFiltersHelper.__getCategoriesFilterValue(categoriesFilters);

    value = { ...value, campaign_ids, ad_group_ids, not_assigned_category_ids, assigned_category_value_ids };

    return value as F;
  }

  private static __getFilterValue<T>(filter: SharedFilterOption): T[] {
    return [...new Set(filter.values.filter((value) => !!value).map((value) => value!.value as T) || [])];
  }

  private static __getFilterBaseValue(filter: SharedFilterOption, output: ISPredefinedFilters): ISPredefinedFilters {
    const key: ISPredefinedFiltersKeys | undefined =
      IS_PREDEFINED_FILTERS_BASE_KEYS[filter.property as ISPredefinedFiltersEnum];
    if (key) {
      output = { ...output, [key]: this.__getFilterValue(filter) };
    }
    return output;
  }

  private static __getCategoriesFilterValue(categoryFilters: SharedFilterOption[]): ISCategoriesFilterValue {
    return {
      not_assigned_category_ids: categoryFilters
        .filter(
          (filter) =>
            filter.filterId &&
            filter.values.find((value) => value?.value.toString().includes(this.NOT_ASSIGNED_SUFFIX)),
        )
        .map((filter) => filter.filterId as number),
      assigned_category_value_ids: categoryFilters.flatMap((filter) =>
        filter.values
          .filter((value) => !value?.value.toString().includes(this.NOT_ASSIGNED_SUFFIX))
          .map((value) => value?.value as number),
      ),
    };
  }

  private static __getCampaignsFilterValue(campaignAndAdGroupFilters: SharedFilterOption[]): ISCampaignsFilterValue {
    return {
      campaign_ids: campaignAndAdGroupFilters.length ? campaignAndAdGroupFilters.map((filter) => filter.filterId!) : [],
      ad_group_ids: campaignAndAdGroupFilters.length
        ? [
            ...new Set(
              campaignAndAdGroupFilters.flatMap<number>((filter) =>
                (filter.values as SharedFilterValue<number>[]).map((value) => value.value),
              ),
            ),
          ]
        : [],
    };
  }
}
