import { ISUniverseEnum } from '../enums';
import { ISUniverseEntityKey, ISUniverseFiltersKey, ISUniverseListKey } from '../models/is-universe-entity-key.model';

export const IS_UNIVERSE_ENTITY_KEY: { [key in ISUniverseEnum]: ISUniverseEntityKey[key] } = {
  [ISUniverseEnum.SEARCH_TERMS]: 'search_term',
  [ISUniverseEnum.URLS]: 'url',
};

export const IS_UNIVERSE_LIST_KEY: { [key in ISUniverseEnum]: ISUniverseListKey[key] } = {
  [ISUniverseEnum.SEARCH_TERMS]: 'search_terms',
  [ISUniverseEnum.URLS]: 'urls',
};

export const IS_UNIVERSE_FILTERS_KEY: { [key in ISUniverseEnum]: ISUniverseFiltersKey } = {
  [ISUniverseEnum.SEARCH_TERMS]: 'searchTerms',
  [ISUniverseEnum.URLS]: 'landingPages',
};
