import { ISTacticsEnum } from '@ess/integrated-search/tactics/shared/utils';
import { SharedFilter } from '@ess/shared/utils/models';

import { ISNumericFiltersHelper } from './is-numeric-filters.helper';

import { ISPredefinedFiltersEnum } from '../enums';
import { ISFilters, ISFiltersPath } from '../models';

type ISFiltersSortFn = (a: SharedFilter, b: SharedFilter) => number;

export class ISFiltersOrderHelper {
  private static readonly COMMON_APPLIED_FILTERS_ORDER: string[] = [
    ISPredefinedFiltersEnum.TIME,
    ISPredefinedFiltersEnum.COUNTRY,
    ISPredefinedFiltersEnum.RECOMMENDATIONS,
    ISPredefinedFiltersEnum.CAMPAIGN,
    ISPredefinedFiltersEnum.AD_GROUP,
    ISPredefinedFiltersEnum.CATEGORIES,
    ISPredefinedFiltersEnum.VALUES,
    ISPredefinedFiltersEnum.URLS,
    ISPredefinedFiltersEnum.KEYWORD_STATUS,
    ISPredefinedFiltersEnum.MATCH_TYPE,
    ISPredefinedFiltersEnum.COUNTRIES,
    ISPredefinedFiltersEnum.SOURCE,
  ];
  private static readonly APPLIED_FILTERS_ORDER: {
    [path in ISFiltersPath]: { [key in keyof ISFilters[path]]: string[] };
  } = {
    tactics: {
      channelOverlap: [
        ...this.COMMON_APPLIED_FILTERS_ORDER,
        ...ISNumericFiltersHelper.getNumericFiltersLabels(ISTacticsEnum.CHANNEL_OVERLAP),
      ],
      searchGaps: [
        ...this.COMMON_APPLIED_FILTERS_ORDER,
        ...ISNumericFiltersHelper.getNumericFiltersLabels(ISTacticsEnum.SEARCH_GAPS),
        ISPredefinedFiltersEnum.COMPETITION,
        ISPredefinedFiltersEnum.SEARCH_TERM_DIFFICULTY,
      ],
      landingPageOptimisation: [
        ...this.COMMON_APPLIED_FILTERS_ORDER,
        ISPredefinedFiltersEnum.AVG_LP_EXPERIENCE,
        ...ISNumericFiltersHelper.getNumericFiltersLabels(ISTacticsEnum.LANDING_PAGE_OPTIMISATION),
      ],
    },
    universe: {
      searchTerms: [...this.COMMON_APPLIED_FILTERS_ORDER],
      landingPages: [...this.COMMON_APPLIED_FILTERS_ORDER],
    },
  };

  static appliedFiltersSort<K extends ISFiltersPath>(path: K, key: keyof ISFilters[K]): ISFiltersSortFn {
    return (a: SharedFilter, b: SharedFilter) =>
      this.APPLIED_FILTERS_ORDER[path][key].indexOf(a.property) -
      this.APPLIED_FILTERS_ORDER[path][key].indexOf(b.property);
  }
}
