import { ISPredefinedFiltersEnum } from '../enums';
import { ISPredefinedFiltersKeys } from '../models';

export const IS_PREDEFINED_FILTERS_KEYS: {
  [key in ISPredefinedFiltersKeys]: ISPredefinedFiltersEnum;
} = {
  timespan: ISPredefinedFiltersEnum.TIME,
  country: ISPredefinedFiltersEnum.COUNTRY,
  countries: ISPredefinedFiltersEnum.COUNTRIES,
  sources: ISPredefinedFiltersEnum.SOURCE,
  ad_group_ids: ISPredefinedFiltersEnum.AD_GROUP,
  campaign_ids: ISPredefinedFiltersEnum.CAMPAIGN,
  recommendations: ISPredefinedFiltersEnum.RECOMMENDATIONS,
  urls: ISPredefinedFiltersEnum.URLS,
  keyword_status: ISPredefinedFiltersEnum.KEYWORD_STATUS,
  match_type: ISPredefinedFiltersEnum.MATCH_TYPE,
  not_assigned_category_ids: ISPredefinedFiltersEnum.CATEGORIES,
  assigned_category_value_ids: ISPredefinedFiltersEnum.VALUES,
  ppc_landing_page_quality_scores: ISPredefinedFiltersEnum.AVG_LP_EXPERIENCE,
  competitions: ISPredefinedFiltersEnum.COMPETITION,
  search_term_difficulties: ISPredefinedFiltersEnum.SEARCH_TERM_DIFFICULTY,
};

export const IS_PREDEFINED_FILTERS_BASE_KEYS: { [key in ISPredefinedFiltersEnum]?: ISPredefinedFiltersKeys } = {
  [ISPredefinedFiltersEnum.RECOMMENDATIONS]: 'recommendations',
  [ISPredefinedFiltersEnum.URLS]: 'urls',
  [ISPredefinedFiltersEnum.SOURCE]: 'sources',
  [ISPredefinedFiltersEnum.COUNTRIES]: 'countries',
  [ISPredefinedFiltersEnum.MATCH_TYPE]: 'match_type',
  [ISPredefinedFiltersEnum.KEYWORD_STATUS]: 'keyword_status',
  [ISPredefinedFiltersEnum.AVG_LP_EXPERIENCE]: 'ppc_landing_page_quality_scores',
  [ISPredefinedFiltersEnum.COMPETITION]: 'competitions',
  [ISPredefinedFiltersEnum.SEARCH_TERM_DIFFICULTY]: 'search_term_difficulties',
};
